// extracted by mini-css-extract-plugin
export var zeitenHeader = "kontakt-module--zeitenHeader--2BaC3";
export var zeitenHeader2 = "kontakt-module--zeitenHeader2--OAaTs";
export var indexInfo1 = "kontakt-module--indexInfo1--3UTYW";
export var indexInfo2 = "kontakt-module--indexInfo2--DF1gR";
export var indexInfo3 = "kontakt-module--indexInfo3--346VD";
export var indexCalendarIcon = "kontakt-module--indexCalendarIcon--1i2Oz";
export var indexCalendarIcon2 = "kontakt-module--indexCalendarIcon2--4vz4Y";
export var indexInfo1Img = "kontakt-module--indexInfo1Img--3XGNy";
export var indexInfo2Img = "kontakt-module--indexInfo2Img--2OEf6";
export var indexInfo1Text = "kontakt-module--indexInfo1Text--ONvwG";
export var indexInfo1TextMail = "kontakt-module--indexInfo1TextMail--3PlAt";
export var indexInfo1Text2 = "kontakt-module--indexInfo1Text2--2DWyH";
export var indexInfo1Extended = "kontakt-module--indexInfo1Extended--2xd2Y";
export var mapContainer = "kontakt-module--mapContainer--C3mjC";
export var map = "kontakt-module--map--1bCyZ";
export var zeitenHeaderWrapper = "kontakt-module--zeitenHeaderWrapper--qNGMi";
export var zeitenHeaderContainer = "kontakt-module--zeitenHeaderContainer--pbF4D";