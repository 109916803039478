import React from "react"
import Layout from "../components/Layout"
import * as styles from "../assets/css/kontakt.module.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Recipes = ({ data }) => {
  const { calendar, information, exclamation, map, phone, fax, mail } = data
  return (
    <Layout>
      <div className={styles.zeitenHeaderWrapper}>
        <div className={styles.zeitenHeaderContainer}>
          <div className={styles.zeitenHeader}>Öffnungszeiten</div>

          <div className={styles.indexInfo1}>
            <div className={styles.indexInfo2Img}>
              <div className={styles.indexCalendarIcon}>
                <GatsbyImage
                  image={getImage(calendar)}
                  title={calendar.title}
                  alt="Öffnungszeiten"
                />
              </div>
            </div>
            <div className={styles.indexInfo1Text}>
              Termine nach Voranmeldung
            </div>
          </div>
          <div className={styles.indexInfo1Extended}>
            <ul>
              <li>Montag: 8:00 - 11:00</li>
              <li>Dienstag: 8:00 - 11:00</li>
              <li>Mittwoch: 8:00 - 11:00</li>
              <li>Donnerstag: 14:00 - 16:00</li>
              <li>Freitag: 8:00 - 11:00</li>
              <p>und nach Vereinbarung</p>
            </ul>
          </div>
        </div>

        <div className={styles.zeitenHeaderContainer}>
          <div className={styles.zeitenHeader2}>Kontakt</div>

          <div className={styles.indexInfo2}>
            <div className={styles.indexInfo1Img}>
              <div className={styles.indexCalendarIcon}>
                <GatsbyImage
                  image={getImage(map)}
                  title={map.title}
                  alt="Standort"
                />
              </div>
            </div>
            <div className={styles.indexInfo1Text2}>
              <p>
                <b>Praxis Dr. Krametter</b>
              </p>
              <p>MedZentrum Weiz</p>
              <p>Hans-Sutter-Gasse 3</p>
              <p>8160 Weiz</p>
            </div>
          </div>

          <div className={styles.indexInfo2}>
            <div className={styles.indexInfo1Img}>
              <div className={styles.indexCalendarIcon}>
                <GatsbyImage
                  image={getImage(phone)}
                  title={phone.title}
                  alt="Telefon"
                />
              </div>
            </div>
            <div className={styles.indexInfo1Text}>
              <p>
                <a href="tel:03172-89052">Tel: 03172 - 89052</a>
              </p>
            </div>
          </div>

          <div className={styles.indexInfo2}>
            <div className={styles.indexInfo1Img}>
              <div className={styles.indexCalendarIcon}>
                <GatsbyImage
                  image={getImage(fax)}
                  title={fax.title}
                  alt="Fax"
                />
              </div>
            </div>
            <div className={styles.indexInfo1Text}>
              <p>Fax: 03172 - 46860</p>
            </div>
          </div>

          <div className={styles.indexInfo2}>
            <div className={styles.indexInfo1Img}>
              <div className={styles.indexCalendarIcon}>
                <GatsbyImage
                  image={getImage(mail)}
                  title={mail.title}
                  alt="E-Mail"
                />
              </div>
            </div>
            <div className={styles.indexInfo1TextMail}>
              <p>
                Mail:{" "}
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#112;&#114;&#97;&#120;&#105;&#115;&#64;&#107;&#114;&#97;&#109;&#101;&#116;&#116;&#101;&#114;&#46;&#97;&#116;">
                  &#112;&#114;&#97;&#120;&#105;&#115;&#64;&#107;&#114;&#97;&#109;&#101;&#116;&#116;&#101;&#114;&#46;&#97;&#116;
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.zeitenHeader2}>Lageplan</div>
      <div className={styles.mapContainer}>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2710.088098688331!2d15.623048216058498!3d47.21485847916048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476e4685304fa2e3%3A0xf1bed3e895f9cc0!2sHans-Sutter-Gasse%203%2C%208160%20Weiz!5e0!3m2!1sde!2sat!4v1637098510835!5m2!1sde!2sat"
          loading="lazy"
          className={styles.map}
        ></iframe>
      </div>

      <div className={styles.indexInfo3}>
        <div className={styles.indexInfo1Img}>
          <div className={styles.indexCalendarIcon2}>
            <GatsbyImage
              image={getImage(exclamation)}
              title={exclamation.title}
              alt="Achtung"
            />
          </div>
        </div>
        <div className={styles.indexInfo1Text}>
          <p>
            Achtung! Parkplätze rückseitig, hinter dem Ärztezentrum, über
            Mühlgasse erreichbar.
          </p>
        </div>
      </div>
      <div className={styles.indexInfo3}>
        <div className={styles.indexInfo1Img}>
          <div className={styles.indexCalendarIcon2}>
            <GatsbyImage
              image={getImage(information)}
              title={information.title}
              alt="Information"
            />
          </div>
        </div>
        <div className={styles.indexInfo1Text}>
          <p>
            Falls Sie mit Navi fahren, kommen Sie unter Eingabe der Adresse
            "Mühlgasse 23" direkt zur Parkplatzeinfahrt!
          </p>
        </div>
      </div>
      <div className={styles.indexInfo3}>
        <div className={styles.indexInfo1Img}>
          <div className={styles.indexCalendarIcon2}>
            <GatsbyImage
              image={getImage(information)}
              title={information.title}
              alt="Information"
            />
          </div>
        </div>
        <div className={styles.indexInfo1Text}>
          <p>
            Die Ordination Dr. Krametter befindet sich im MEDZENTRUM Weiz,
            Hans-Sutter Gasse 3, Nähe A-Z Ärztezentrum.
          </p>
        </div>
      </div>
      <div className={styles.indexInfo3}>
        <div className={styles.indexInfo1Img}>
          <div className={styles.indexCalendarIcon2}>
            <GatsbyImage
              image={getImage(information)}
              title={information.title}
              alt="Information"
            />
          </div>
        </div>
        <div className={styles.indexInfo1Text}>
          <p>
            Das Einzugsgebiet der Praxis erstreckt sich bis in die Region
            Hartberg, Ilztal,{" "}
            <Link to="/gleisdorf">
              <u>Gleisdorf</u>
            </Link>{" "}
            bzw. Raabtal, Graz-Umgebung, und Oststeiermark bis Joglland.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Recipes

export const pageQuery = graphql`
  query ContactQuery {
    calendar: contentfulAsset(contentful_id: { eq: "4vlrIpeB2zVtiuJyJBbKLu" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    information: contentfulAsset(
      contentful_id: { eq: "1aodisFRq5NhCZBAoCihDA" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    exclamation: contentfulAsset(
      contentful_id: { eq: "bQSbakr2yqQNqCCex70DY" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    map: contentfulAsset(contentful_id: { eq: "1IDCOz37h8xTgJwroxo2b6" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    phone: contentfulAsset(contentful_id: { eq: "7uWiCKMjcukA4TOqBIikk1" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    fax: contentfulAsset(contentful_id: { eq: "7sCEpL7EGHhVRpWmiBExTi" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    mail: contentfulAsset(contentful_id: { eq: "l2EQPEUNNuqiWQwDTuWrC" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
